import { rem } from "polished";
import { PropsWithChildren } from "react";
import styled from "styled-components";

import { spaces } from "emoreg/const";

export const containerMaxWidth = (small?: boolean) => (small ? rem(900) : rem(1350));

type ContainerElement = ({ small }: PropsWithChildren<{ small?: boolean }>) => JSX.Element;
const Container: ContainerElement = styled.div`
  max-width: ${props => containerMaxWidth(props.small)};
  margin: 0 auto;
  padding: 0 ${spaces[16]};
  width: 100%;
`;

export { Container };
