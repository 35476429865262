import { useState, useEffect, PropsWithChildren } from "react";
import styled from "styled-components";
import { rem } from "polished";
import { ifProp } from "styled-tools";

import { Icon } from "emoreg/atoms/Icon";
import { spaces, color, font } from "emoreg/const";
import { responsiveHelpers as rh } from "styles/utils";

const CopyClipboard = styled.div`
  margin-left: ${spaces[8]};
  height: ${rem(20)};
  align-items: center;
  justify-content: center;
  cursor: pointer;
  display: ${props => (props.alwaysVisible ? "flex" : "none")};
`;

const CopyClipboardIconWrapper = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: ${rem(18)};
  height: ${rem(18)};
  border-radius: 2px;
  background: #dee2e6;
  margin-right: ${rem(6)};

  ${ifProp("copied", `background: ${color.blue.base};`)}
`;

const CopyClipboardLabel = styled.span`
  font-weight: ${font.weight.semiBold};
  color: ${color.grey.dark};
  flex-shrink: 0;
  ${ifProp("copied", `color: ${color.blue.base};`)}
`;

const ClipboardContainer = styled.div`
  ${rh.forMobileUp`
    display: flex;
      &:hover {
        & div:last-child {
          display: flex;
        }
      }
`}
`;

type ClipboardProps = {
  alwaysVisible?: boolean;
  content: string;
  copyText?: string;
};

export const AddToClipboard = (props: PropsWithChildren<ClipboardProps>) => {
  const [copied, setCopied] = useState<boolean>(false);

  useEffect(() => {
    if (copied) {
      setTimeout(() => setCopied(false), 1500);
    }
  });

  const asyncCopyWithFallback = text =>
    new Promise((resolve, reject) => {
      if (navigator.clipboard) {
        navigator.clipboard.writeText(props.content).then(resolve);
      } else {
        //fallback behavior
        const textArea = document.createElement("textarea");
        textArea.value = text;
        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();

        try {
          const successful = document.execCommand("copy");
          successful ? resolve(text) : reject();
        } catch (err) {
          reject(err);
        } finally {
          document.body.removeChild(textArea);
        }
      }
    });

  const copy = () => {
    asyncCopyWithFallback(props.content).then(
      () => setCopied(true),
      err => console.error("Could not write to clipboard", err)
    );
  };

  return (
    <ClipboardContainer>
      {props.children}
      <CopyClipboard onClick={() => copy()} alwaysVisible={props.alwaysVisible}>
        <CopyClipboardIconWrapper copied={copied}>
          <Icon name="copy" color={copied ? "#fff" : color.grey.dark} size={10} />
        </CopyClipboardIconWrapper>
        <CopyClipboardLabel copied={copied}>
          {copied ? "Copié !" : props.copyText || "Copier ?"}
        </CopyClipboardLabel>
      </CopyClipboard>
    </ClipboardContainer>
  );
};
