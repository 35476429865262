import { Spaces } from "emoreg/const";

export type PaddingApiProps = {
  padding?: Spaces;
  paddingTop?: Spaces;
  pt?: Spaces;
  paddingRight?: Spaces;
  pr?: Spaces;
  paddingBottom?: Spaces;
  pb?: Spaces;
  paddingLeft?: Spaces;
  pl?: Spaces;
  paddingHorizontal?: Spaces;
  px?: Spaces;
  paddingVertical?: Spaces;
  py?: Spaces;
};

export const paddingApi = (props: PaddingApiProps) => ({
  paddingTop: props.paddingTop || props.paddingVertical || props.padding || props.pt || props.py,
  paddingBottom:
    props.paddingBottom || props.paddingVertical || props.padding || props.pb || props.py,
  paddingRight:
    props.paddingRight || props.paddingHorizontal || props.padding || props.pr || props.px,
  paddingLeft:
    props.paddingLeft || props.paddingHorizontal || props.padding || props.pl || props.px,
});
