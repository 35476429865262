import { color } from "emoreg/const";
import { rem } from "polished";
import styled from "styled-components";

export const Content = styled.p({
  display: "inline",
  color: color.red.base,
  fontSize: rem(12),
  lineHeight: 1.5,
  // FIXME: correct an unknown style applied over all p
  marginBottom: 0,
});

Content.baseName = "InputErrorMessageContent";

export const InputErrorMessageStyle = Content;
