import {
  CheckableInputLabel,
  CheckboxInput,
  CheckboxInputProps,
  FieldContainer,
  InputErrorMessage,
  InputPillContainer,
  PillColor,
} from "emoreg";
import { Box, Flex } from "emoreg/layouts";
import { isTruthy } from "hassibot/util/guards";
import React from "react";

export type CheckboxPillProps = CheckboxInputProps & {
  label: string;
  error?: string;
  color?: PillColor;
};

export const CheckboxPill = React.forwardRef<HTMLInputElement, CheckboxPillProps>(
  ({ style, className, label, id, color, error, hasError, ...props }, ref) => {
    return (
      <FieldContainer style={style} className={className}>
        <Box>
          <CheckableInputLabel color={color} htmlFor={id}>
            <InputPillContainer hasError={hasError || !!error} color={color}>
              <Flex gap={8}>
                <CheckboxInput
                  ref={ref}
                  id={id}
                  {...props}
                  hasError={hasError || !!error}
                  aria-labelledby={[props["aria-labelledby"], !!error && `error_${id}`]
                    .filter(isTruthy)
                    .join(" ")}
                />
                <span>{label}</span>
              </Flex>
            </InputPillContainer>
          </CheckableInputLabel>
        </Box>
        {error && <InputErrorMessage id={`error_${id}`}>{error}</InputErrorMessage>}
      </FieldContainer>
    );
  }
);
