import { CheckableInputLabelStyle } from "./styles";
import { LabelColor } from "./types";

export type CheckableInputLabelProps = React.HTMLProps<HTMLLabelElement> & {
  color?: LabelColor;
};

export const CheckableInputLabel = (props: CheckableInputLabelProps) => {
  return <CheckableInputLabelStyle {...props} />;
};
