import { PropsWithChildren } from "react";
import { InputPillContainerStyle } from "./styles";
import { PillColor } from "./types";

export type InputPillContainerProps = PropsWithChildren<{
  className?: string;
  style?: React.CSSProperties;
  color?: PillColor;
  hasError?: boolean;
}>;

export const InputPillContainer = (props: InputPillContainerProps) => {
  return <InputPillContainerStyle {...props} />;
};
