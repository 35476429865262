import { SVGProps } from "react";

export const OhLogo = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={10} height={12} fill="none" {...props}>
    <path
      fill="#002C5A"
      d="M2.065.57C1.182.57.59 1.164.59 2.048v7.887c0 .885.592 1.485 1.475 1.485.883 0 1.477-.594 1.477-1.485V2.048c0-.884-.594-1.478-1.477-1.478Z"
    />
    <path
      fill="#3CCF93"
      d="M7.931.57c-.883 0-1.475.594-1.475 1.478v1.117h-3.42c-.883 0-1.477.557-1.477 1.386 0 .829.594 1.386 1.476 1.386h3.42v3.998c0 .885.593 1.485 1.476 1.485s1.477-.594 1.477-1.485V2.048C9.408 1.164 8.814.57 7.93.57Z"
    />
    <path
      fill="#002C5A"
      d="M6.803 5.932H2.767c-.882 0-1.475.556-1.475 1.385 0 .828.593 1.386 1.475 1.386h4.036c.883 0 1.476-.558 1.476-1.386 0-.83-.593-1.385-1.476-1.385Z"
    />
  </svg>
);
