import { color, spaces } from "emoreg/const";
import styled from "styled-components";
import { PillColor } from "./types";
import { pillColor } from "./contants";

type ContainerProps = { color?: PillColor; hasError?: boolean };

const Container = styled.div<ContainerProps>(
  {
    display: "inline-block",
    padding: `${spaces[8]} ${spaces[12]}`,
    borderRadius: 40,
    cursor: "pointer",
    borderWidth: 0.5,
    borderStyle: "solid",
    "&:has(input:disabled)": {
      backgroundColor: color.grey.light,
      cursor: "not-allowed",
      ...pillColor.grey,
      opacity: 0.4,
    },
  },
  ({ color: _color = "grey" }) => ({
    "&:hover": {
      backgroundColor: pillColor[_color].hoverBackgroundColor,
    },
  }),
  ({ color: _color = "grey", hasError }) => {
    let borderColor = pillColor[_color].borderColor;
    if (hasError) borderColor = color.red.base;
    return { borderColor };
  }
);

Container.baseName = "InputPillContainer";

export const InputPillContainerStyle = Container;
