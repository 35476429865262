import { createContext } from "react";

export type GroupedCheckboxContextProps = {
  name: string;
  hasError: boolean;
  values: string[];
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  "aria-labelledby": string;
};

export const GroupedCheckboxContext = createContext<GroupedCheckboxContextProps>({
  name: "",
  hasError: false,
  values: [],
  onChange: () => {},
  "aria-labelledby": "",
});
