import { CSSProperties } from "react";

export type BorderRadiusApiProps = {
  radius?: CSSProperties["borderRadius"];
  topLeftRadius?: CSSProperties["borderTopLeftRadius"];
  topRightRadius?: CSSProperties["borderTopRightRadius"];
  bottomLeftRadius?: CSSProperties["borderBottomLeftRadius"];
  bottomRightRadius?: CSSProperties["borderBottomRightRadius"];
  border?: CSSProperties["border"];
  borderTop?: CSSProperties["borderTop"];
  borderRight?: CSSProperties["borderRight"];
  borderBottom?: CSSProperties["borderBottom"];
  borderLeft?: CSSProperties["borderLeft"];
};

export const borderApi = (props: BorderRadiusApiProps) => ({
  borderRadius: props.radius,
  borderTopLeftRadius: props.topLeftRadius,
  borderTopRightRadius: props.topRightRadius,
  borderBottomLeftRadius: props.bottomLeftRadius,
  borderBottomRightRadius: props.bottomRightRadius,
  border: props.border,
  borderTop: props.borderTop,
  borderRight: props.borderRight,
  borderBottom: props.borderBottom,
  borderLeft: props.borderLeft,
});
