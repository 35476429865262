import { Spaces } from "emoreg/const";
import { ResponsivePropsOrSingleObject } from "emoreg/types";
import { CSSProperties } from "react";
import styled from "styled-components";
import { Box, BoxProps } from "./Box";
import {
  extractBreakpointFromResponsiveProps,
  toResponsiveProps,
} from "emoreg/utils/responsive-props";
import { forTabletUp } from "emoreg/utils/responsive";

type CSSGridKeys = Partial<{
  templateColumns: CSSProperties["gridTemplateColumns"];
  templateRows: CSSProperties["gridTemplateRows"];
  template: CSSProperties["gridTemplate"];
  alignItems: CSSProperties["alignItems"];
  justifyContent: CSSProperties["justifyContent"];
  templateAreas: CSSProperties["gridTemplateAreas"];
}>;

type GridApiProps = CSSGridKeys & {
  rowGap?: Spaces;
  columnGap?: Spaces;
};

const gridApi = (props: GridApiProps) => ({
  gridTemplateColumns: props.templateColumns,
  gridTemplateRows: props.templateRows,
  gridTemplate: props.template,
  alignItems: props.alignItems,
  justifyContent: props.justifyContent,
  gridTemplateAreas: props.templateAreas,
  rowGap: props.rowGap,
  columnGap: props.columnGap,
});

type GridProps = ResponsivePropsOrSingleObject<GridApiProps> & BoxProps;

export const Grid: React.FC<GridProps> = styled(Box)<GridProps>(
  { display: "grid" },
  props => {
    const responsiveProps = toResponsiveProps(gridApi(props));
    return extractBreakpointFromResponsiveProps(responsiveProps, "mobile");
  },
  forTabletUp(props => {
    const responsiveProps = toResponsiveProps(gridApi(props));
    return extractBreakpointFromResponsiveProps(responsiveProps, "tablet");
  })
);

Grid.displayName = "Grid";
