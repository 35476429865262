import {
  CheckableInputLabel,
  CheckboxInput,
  CheckboxInputProps,
  FieldContainer,
  InputErrorMessage,
} from "emoreg";
import { Box, Flex } from "emoreg/layouts";
import { isTruthy } from "hassibot/util/guards";
import React from "react";

export type CheckboxProps = CheckboxInputProps & {
  label: string;
  error?: string;
};

export const Checkbox = React.forwardRef<HTMLInputElement, CheckboxProps>(
  ({ style, className, label, id, error, hasError, ...props }, ref) => {
    return (
      <FieldContainer style={style} className={className}>
        <Box>
          <CheckableInputLabel htmlFor={id}>
            <Flex gap={8}>
              <CheckboxInput
                ref={ref}
                id={id}
                {...props}
                hasError={hasError || !!error}
                aria-labelledby={[props["aria-labelledby"], !!error && `error_${id}`]
                  .filter(isTruthy)
                  .join(" ")}
              />
              <span>{label}</span>
            </Flex>
          </CheckableInputLabel>
        </Box>
        {error && <InputErrorMessage id={`error_${id}`}>{error}</InputErrorMessage>}
      </FieldContainer>
    );
  }
);
