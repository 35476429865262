import { color, font } from "emoreg/const";
import { rem } from "polished";
import styled from "styled-components";
import { labelColor } from "./contants";
import { LabelColor } from "./types";

const Label = styled.label<{ color?: LabelColor }>(
  {
    fontSize: rem(12),
    lineHeight: rem(16),
    "&:has(input:checked)": {
      fontWeight: font.weight.semiBold,
    },
    "&:has(input:indeterminate)": {
      fontWeight: font.weight.semiBold,
    },
    "&:has(input:disabled)": {
      color: color.grey.dark,
    },
  },
  ({ color: _color = "grey" }) => ({
    color: labelColor[_color].color,
  }),
  // FIXME: prevent an unknown global style to affect our styling
  { marginBottom: 0 }
);

Label.baseName = "CheckableInputLabel";

export const CheckableInputLabelStyle = Label;
