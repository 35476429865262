import { rem } from "polished";
import React, { PropsWithChildren } from "react";
import styled, { css } from "styled-components";

import { color as colorFromDS, font as fontFromDS, FontWeight } from "emoreg/const";
import { lhCrop } from "emoreg/utils/lh-crop";
import { switchProp } from "emoreg/utils/switch-prop";
import { ifProp } from "styled-tools";

type TypographySizes = "small" | "medium" | "large";
type TypographyProps = {
  as?: "p" | "span";
  className?: string;
  size?: TypographySizes;
  fontWeight?: FontWeight;
  color?: string;
  ellipsis?: boolean;
  fontStyle?: React.CSSProperties["fontStyle"];
  whiteSpace?: React.CSSProperties["whiteSpace"];
  textAlign?: React.CSSProperties["textAlign"];
};

const TypographyStyle = styled.p`
  ${props =>
    props.$as !== "span" &&
    css`
      ${lhCrop(1.5)};
    `}
  line-height: 1.5;
  font-weight: ${props => props.$fontWeight};
  color: ${props => props.$color};
  font-style: ${props => props.$fontStyle};
  white-space: ${props => props.$whiteSpace};
  ${props =>
    props.$textAlign &&
    css`
      text-align: ${props.$textAlign};
    `};
  ${switchProp("$size", {
    large: css`
      font-size: ${rem(16)};
    `,
    medium: css`
      font-size: ${rem(14)};
    `,
    small: css`
      font-size: ${rem(12)};
    `,
  })}

  ${ifProp("$ellipsis", {
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
  })}
`;
export const Typography: React.FC<PropsWithChildren<TypographyProps>> = ({
  as,
  size = "medium",
  color = colorFromDS.grey.darker,
  fontWeight = fontFromDS.weight.normal,
  textAlign,
  fontStyle = "normal",
  whiteSpace = "pre-line",
  className,
  children,
  ellipsis,
}) => (
  <TypographyStyle
    $as={as}
    $size={size}
    $color={color}
    $whiteSpace={whiteSpace}
    $fontWeight={fontWeight}
    $fontStyle={fontStyle}
    $ellipsis={ellipsis}
    $textAlign={textAlign}
    className={className}
  >
    {children}
  </TypographyStyle>
);
