import { CSSProperties } from "react";

export type SizingApiProps = {
  height?: CSSProperties["height"];
  h?: CSSProperties["height"];
  maxHeight?: CSSProperties["maxHeight"];
  maxH?: CSSProperties["maxHeight"];
  minHeight?: CSSProperties["minHeight"];
  minH?: CSSProperties["minHeight"];
  width?: CSSProperties["width"];
  w?: CSSProperties["width"];
  maxWidth?: CSSProperties["maxWidth"];
  maxW?: CSSProperties["maxWidth"];
  minWidth?: CSSProperties["minWidth"];
  minW?: CSSProperties["minWidth"];
};

export const sizingApi = (props: SizingApiProps) => ({
  height: props.height || props.h,
  maxHeight: props.maxHeight || props.maxH,
  minHeight: props.minHeight || props.minH,
  width: props.width || props.w,
  maxWidth: props.maxWidth || props.maxW,
  minWidth: props.minWidth || props.minW,
});
