import { color, font } from "emoreg/const";
import { rem } from "polished";
import styled from "styled-components";

const Label = styled.label(
  {
    color: color.grey.dark,
    fontSize: rem(12),
    lineHeight: rem(16),
    fontWeight: font.weight.semiBold,
  },
  // FIXME: prevent an unknown global style to affect our styling
  { marginBottom: 0 }
);

Label.baseName = "Label";

const Required = styled.span({
  color: color.red.base,
});

Label.baseName = "LabelRequired";

export const InputLabelStyle = Object.assign(Label, { Required });
