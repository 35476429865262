import React, { PropsWithChildren } from "react";
import styled, { css } from "styled-components";
import { rem } from "polished";

import { switchProp } from "emoreg/utils/switch-prop";
import { lhCrop } from "emoreg/utils/lh-crop";
import { font, color } from "emoreg/const";

type TitleType = "h1" | "h2" | "h3" | "h4";
export type TitleProps = {
  type: TitleType;
  color?: string;
};

export const TitleStyle = css`
  ${lhCrop(1.2)};
  line-height: 1.2;
  font-weight: ${font.weight.semiBold};
  color: ${props => props.color ?? color.grey.darker};
  margin: 0;
  ${switchProp<TitleProps, "type">("type", {
    h1: css`
      font-size: ${rem(34)};
    `,
    h2: css`
      font-size: ${rem(24)};
    `,
    h3: css`
      font-size: ${rem(18)};
    `,
    h4: css`
      font-size: ${rem(16)};
    `,
  })}
`;

export const Title: React.FC<PropsWithChildren<TitleProps>> = styled(
  ({ type, ...props }: PropsWithChildren<TitleProps>) => React.createElement(type, props)
)`
  ${TitleStyle}
`;
