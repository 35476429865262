import { CSSProperties } from "react";

export type BackgroundApiProps = {
  background?: CSSProperties["background"];
  bg?: CSSProperties["background"];
  backgroundColor?: CSSProperties["backgroundColor"];
  bgColor?: CSSProperties["backgroundColor"];
  boxShadow?: CSSProperties["boxShadow"];
};

export const backgroundApi = (props: BackgroundApiProps) => ({
  background: props.background || props.bg,
  backgroundColor: props.backgroundColor || props.bgColor,
  boxShadow: props.boxShadow,
});
