import { CheckboxItem, CheckboxPillItem, Container } from "./components";

export type {
  CheckboxItemProps,
  CheckboxPillItemProps,
  GroupedCheckboxContainerProps,
} from "./components";

export const GroupedCheckbox = Object.assign(Container, {
  Checkbox: CheckboxItem,
  Pill: CheckboxPillItem,
});
