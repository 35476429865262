import { rem } from "polished";
import { ValueOf } from "types";
import { ResponsiveProps } from "./types";

export const shadow = {
  base: "0px 1px 5px rgba(0, 0, 0, 0.20)",
};

export const color = {
  blue: {
    lighter: "#E6F1FC",
    light: "#4B9DF5",
    base: "#016FE5",
    dark: "#004080",
    darker: "#00152A",
  },
  grey: {
    light: "#F2F2F2",
    base: "#CCCCCC",
    dark: "#707579",
    darker: "#343A40",
  },
  orange: {
    lighter: "#F9F0E2",
    light: "#FFF3CD",
    base: "#F5A623",
    dark: "#E79000",
  },
  yellow: {
    base: "#F6C344",
  },
  red: {
    light: "#F9E1E4",
    base: "#DE001B",
  },
  green: {
    base: "#5DA36C",
    light: "#EAF6EC",
  },
  white: "#FFFFFF",
} as const;

export const rawSpace = {
  none: 0,
  base: 16,
  xxs: 4,
  xs: 8,
  sm: 12,
  md: 24,
  lg: 32,
  xl: 48,
  xxl: 64,
} as const;

export const patchRem = <T extends string>(a: number): T => rem(a) as T;

export const patchRemResponsiveProps = (
  responsiveProps?: ResponsiveProps<Spaces> | Spaces | null
) => {
  if (!responsiveProps) {
    return null;
  }
  if (typeof responsiveProps === "object") {
    const patchedResponsiveProps = { mobile: patchRem(0), tablet: patchRem(0) };
    for (const screenSize of Object.keys(responsiveProps)) {
      patchedResponsiveProps[screenSize] = patchRem(responsiveProps[screenSize]);
    }
    return patchedResponsiveProps;
  }
  return patchRem(responsiveProps);
};

export type Spaces = ValueOf<typeof rawSpace>;

export const spaces: Record<Spaces, string> = {
  0: patchRem(rawSpace.none),
  16: patchRem(rawSpace.base),
  4: patchRem(rawSpace.xxs),
  8: patchRem(rawSpace.xs),
  12: patchRem(rawSpace.sm),
  24: patchRem(rawSpace.md),
  32: patchRem(rawSpace.lg),
  48: patchRem(rawSpace.xl),
  64: patchRem(rawSpace.xxl),
};

export const radius = {
  base: patchRem(4),
  large: patchRem(8),
};

export type FontWeight = 400 | 600;
export const font: { weight: Record<"normal" | "semiBold", FontWeight> } = {
  weight: {
    normal: 400,
    semiBold: 600,
  },
};

export const device = {
  mobile: 1024,
};

// These shadows are inspire by the following article : https://www.joshwcomeau.com/css/designing-shadows/
export const boxShadow = {
  elevationLow: (shadowColor: string) => `0.3px 0.5px 0.7px hsl(${shadowColor} / 0.25),
  0.4px 0.8px 1px -1.2px hsl(${shadowColor} / 0.25),
  1px 2px 2.5px -2.5px hsl(${shadowColor} / 0.25);`,
  elevationMedium: (shadowColor: string) => `
    0.3px 0.5px 0.7px hsl(${shadowColor} / 0.26),
    0.8px 1.6px 2px -0.8px hsl(${shadowColor} / 0.26),
    2.1px 4.1px 5.2px -1.7px hsl(${shadowColor} / 0.26),
    5px 10px 12.6px -2.5px hsl(${shadowColor} / 0.26);
  `,
};
