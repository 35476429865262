import styled, { css } from "styled-components";
import { lighten } from "polished";
import { ifNotProp } from "styled-tools";

import { color, font, spaces } from "emoreg/const";

export const LabelStyle = css`
  color: ${props => (props.disabled ? lighten(0.3, color.grey.dark) : color.grey.dark)};
  /* has important because of bootstrap */
  font-weight: ${ifNotProp("noBold", font.weight.semiBold, "normal")} !important;
  font-size: ${spaces[12]};
`;

export const StyledLabel = styled.label`
  display: block;
  margin-bottom: ${spaces[4]};
  ${LabelStyle}
`;

type Props = {
  children: any;
  htmlFor?: string;
  noBold?: boolean; // deprecated
  style?: object;
  className?: string;
  disabled?: boolean;
};
/**
 * @deprecated please use "Label" component from emoreg/atoms
 */
const Label = ({ children, htmlFor, noBold, style, className, disabled }: Props) => (
  <StyledLabel
    htmlFor={htmlFor}
    noBold={noBold}
    style={style}
    className={className}
    disabled={disabled}
  >
    {children}
  </StyledLabel>
);

export default Label;
