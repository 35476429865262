import { Flex } from "emoreg";
import { PropsWithChildren } from "react";

export type FieldContainerProps = PropsWithChildren<{
  style?: React.CSSProperties;
  className?: string;
}>;

export const FieldContainer = (props: FieldContainerProps) => {
  return <Flex direction="column" gap={8} {...props} />;
};
