import { CSSProperties } from "react";

export type OverflowApiProps = {
  overflow?: CSSProperties["overflow"];
  overflowX?: CSSProperties["overflowX"];
  overflowY?: CSSProperties["overflowY"];
};

export const overflowApi = (props: OverflowApiProps) => ({
  overflow: props.overflow,
  overflowX: props.overflowX,
  overflowY: props.overflowY,
});
