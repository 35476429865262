import styled from "styled-components";

import { Title } from "emoreg/atoms/Title";
import { Typography } from "emoreg/atoms/Typography";
import { Flex } from "emoreg";
import { OhLogo } from "assets/icons/oh-logo";
import { Box } from "emoreg/layouts";

const FrameStyle = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

const WrapperText = styled.div`
  max-width: 440px;
  margin-top: 24px;
`;

export const MaintenancePage = () => {
  return (
    <FrameStyle>
      <Box style={{ margin: "16px auto" }}>
        <OhLogo height={62} width={48} />
      </Box>
      <WrapperText>
        <Flex direction="column" gap={24}>
          <Title type="h4">Maintenance en cours</Title>
          <Typography>
            Ximi déplace nos données vers un nouveau serveur, plus rapide. Hassibot sera
            indisponible le temps de la migration (~2 heures).
            <br />
            <br />
            L’annonce de remise en ligne sera faite sur Slack, sur #general
          </Typography>
        </Flex>
      </WrapperText>
    </FrameStyle>
  );
};
