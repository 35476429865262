import { ResponsivePropsOrSingleObject } from "emoreg/types";
import React, { CSSProperties } from "react";
import styled from "styled-components";
import { Box, BoxProps } from "./Box";
import { forTabletUp } from "emoreg/utils/responsive";
import {
  extractBreakpointFromResponsiveProps,
  toResponsiveProps,
} from "emoreg/utils/responsive-props";

type PositionApiProps = Partial<{
  position: CSSProperties["position"];
  top: CSSProperties["top"];
  right: CSSProperties["right"];
  bottom: CSSProperties["bottom"];
  left: CSSProperties["left"];
  inset: CSSProperties["inset"];
  zIndex: CSSProperties["zIndex"];
}>;

const positionApi = (props: PositionApiProps) => ({
  position: props.position,
  top: props.top,
  right: props.right,
  bottom: props.bottom,
  left: props.left,
  zIndex: props.zIndex,
  inset: props.inset,
});

type PositionProps = ResponsivePropsOrSingleObject<PositionApiProps> & BoxProps;

export const Position: React.FC<PositionProps> = styled(Box)<PositionProps>(
  props => extractBreakpointFromResponsiveProps(toResponsiveProps(positionApi(props)), "mobile"),
  forTabletUp(props =>
    extractBreakpointFromResponsiveProps(toResponsiveProps(positionApi(props)), "tablet")
  )
);

Position.displayName = "Position";
