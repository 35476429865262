import { InputLabelStyle } from "./styles";

export type InputLabelProps = React.HTMLProps<HTMLLabelElement>;

export const InputLabel = ({ children, required, ...props }: InputLabelProps) => {
  return (
    <InputLabelStyle {...props}>
      {children}
      {required && <InputLabelStyle.Required> *</InputLabelStyle.Required>}
    </InputLabelStyle>
  );
};
